<template>
  <div>
    <div class="ctl-table-container">
      <div class="filder-inputs grid">
        <div></div>
      </div>
      <div class="ctl-titles">
        <label class="checkbox-container">
          <input
            type="checkbox"
            hidden
            ref="selectAll"
            @input="updateCheckedItemsList"
          />
          <div class="checkbox-item"></div>
        </label>
        <div class="grid">
          <span
            class="ctl-titles-item"
            v-for="(t, idx) of titlesList"
            :key="idx"
            >{{ t.name }}</span
          >
        </div>
      </div>
      <draggable class="ctl-items-list" v-model="items">
        <div
          class="ctl-item"
          :class="{ reversed: idx % 2 }"
          v-for="(c, idx) of items"
          :key="idx"
        >
          <label class="checkbox-container">
            <input
              type="checkbox"
              hidden
              :value="c"
              v-model="checkedItemsMod"
            />
            <div class="checkbox-item" :class="{ reversed: idx % 2 }"></div>
          </label>
          <div class="ctl-item-container grid" @click="showEditPopup(c)">
            <!-- status -->
            <div class="ctl-item-param" :class="`status_${c.status}`">
              {{ c.status }}
            </div>
            <!-- name -->
            <div class="ctl-item-param">
              {{ c.name }}
            </div>
            <!-- position -->
            <div class="ctl-item-param">
              {{ c.position }}
            </div>
            <!-- create at -->
            <div class="ctl-item-param">
              {{ getCorrectDate(c.created_at).slice(0, 19) }}
            </div>
            <!-- author -->
            <div class="ctl-item-param">
              {{ c.author && c.author.email }}
            </div>
            <!-- comment -->
            <div class="ctl-comments-text">
              <div v-for="(coment, idx) of c.comment" :key="idx">
                <span class="ctl-comment-email">{{ coment.user_email }}</span>
                <span class="ctl-comment-date">
                  ({{ getCorrectDate(coment.date) }}):
                </span>
                <span
                  class="ctl-comment-msg"
                  :class="{
                    green: coment.user_email !== c.author && c.author.email
                  }"
                  >{{ coment.msg }}</span
                >
              </div>
            </div>
          </div>
        </div>
        <div class="ctl-loader">
          <div
            v-infinity-scroll="{
              cb: fetchPathItemsList,
              tab
            }"
            id="scroll-trigger"
          ></div>
        </div>
      </draggable>
    </div>
  </div>
</template>

<script>
import catalogMixin from "@/mixins/catalogMixin";
import draggable from "vuedraggable";
import "@/assets/css/catalog.css";

export default {
  name: "categoriesCatalog",
  props: ["tab"],
  mixins: [catalogMixin],
  components: {
    draggable
  },

  data() {
    return {
      // ? titles
      titlesList: [
        { name: "status" },
        { name: "category name" },
        { name: "position" },
        { name: "created at" },
        { name: "author" },
        { name: "comment" }
      ]
    };
  },
  computed: {
    // ? drag&drop positions
    items: {
      get() {
        return this.$store.getters["catalog/categories"];
      },
      set(value) {
        this.$store.dispatch("catalog/updateCategoriesOrder", value);
      }
    }
  }
};
</script>

<style scoped>
.grid {
  display: grid;
  white-space: nowrap;
  grid-template-columns:
    minmax(120px, 120fr)
    minmax(150px, 150fr)
    minmax(70px, 70fr)
    minmax(100px, 100fr)
    minmax(200px, 200fr)
    minmax(300px, 300fr);
  grid-gap: 1vw;
  align-items: center;
  justify-items: center;
  min-width: fit-content;
  width: 100%;
}
.filder-inputs {
  margin-bottom: 8px;
  height: 25px;
}
.drag-item {
  cursor: move;
}
.selected {
  opacity: 0.6;
}
.ctl-item {
  cursor: move;
  height: 40px;
}
</style>
