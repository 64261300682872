import { mapGetters } from "vuex";

export default {
  data() {
    return {
      disableObserve: false,
      tabs: {
        templates: "templates",
        images: "richImages",
        categories: "categories",
        interactions: "interactions",
        feedbacks: "feedbacks",
        pages: "pages",
        fonts: "fonts",
        texts: "texts",
        users: "users"
      }
    };
  },
  computed: {
    tabOrigin() {
      return this.tabs[this.tab];
    },
    ...mapGetters({
      checkedItems: "catalog/checkedItems"
    }),
    checkedItemsMod: {
      get() {
        return this.checkedItems;
      },
      set(value) {
        if (this.items.length && value.length === this.items.length)
          this.$refs.selectAll.checked = true;
        else if (this.$refs.selectAll.checked)
          this.$refs.selectAll.checked = false;
        this.$store.commit("catalog/updateCheckedItems", value);
      }
    }
  },
  methods: {
    async fetchPathItemsList({ refresh = false } = {}) {
      if (this.disableObserve && !refresh) return;
      this.disableObserve = true;
      await this.$store.dispatch("catalog/getCatalogContent", {
        type: this.tabOrigin,
        filters: this.filters,
        sortParams: this.sortParams
      });
      this.disableObserve = false;
    },
    async refreshItemsListWithSort(sortParams) {
      this.sortParams = sortParams;
      this.$store.commit("catalog/clearItemData", this.tabOrigin);
      this.disableObserve = true;
      await this.fetchPathItemsList({ refresh: true });
    },
    async refreshItemsList(filters) {
      this.filters = filters;
      this.$store.commit("catalog/clearItemData", this.tabOrigin);
      this.disableObserve = true;
      await this.fetchPathItemsList({ refresh: true });
    },
    updateCheckedItemsList(e) {
      this.checkedItemsMod = e.target.checked ? this.items : [];
    },
    getCorrectDate(date) {
      return new Date(Date.parse(date)).toString().slice(4, 21);
    },
    getSizeInMBs(size) {
      if (size < 1024) return `${size}bits`;
      if (size < 10240) return `${(size / 1024).toFixed(2)}Kb`;
      return `${(size / 1048576).toFixed(2)}Mb`;
    },
    getCategories(ctgsList) {
      return ctgsList.map(c => c.name).join(", ");
    },
    showLimits({ limitsObj }) {
      if (!limitsObj) return;
      return `${limitsObj.storage || 100}Mbs,`;
    },
    showEditPopup(item) {
      if (!this.tabOrigin) return;
      this.$store.dispatch("workSpace/showPopup", {
        type: "editPopup",
        data: {
          item,
          step: this.tab,
          environment: "catalog"
        }
      });
    }
  }
};
